<template>
    <div class="component-container" :class="cmsBlock.backgroundStyle">
        <div :id="cmsBlock.anchorTag" class="image-block" :class="cmsBlock.imageStyle">
            <picture v-bind:class="{'keep-aspect':cmsBlock.keepAspectRatio}">
                <template v-for="[format, set] of Object.entries(srcsets)">
                    <source v-if="format !== originalFormat" :srcset="set" sizes="100vw" :type="'image/' + format.slice(1)">
                </template>
                <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                    :type="'image/' + originalFormat.slice(1)" sizes="100vw"
                    :alt="localize(cmsBlock.image).data.attributes.alternativeText" width="100%" height="100%">
            </picture>
            <Copyright v-if="hasCopyright" :text="cmsBlock.image.copyright" />
        </div>
    </div>
</template>

<script>
import Copyright from '@/components/PageBuilder/Elements/Copyright'

export default {
    name: 'ImageBlock',

    props: [
        'cmsBlock'
    ],

    components: {
        Copyright
    },

    computed: {
        hasCopyright() {
            return this.cmsBlock.image.copyright ? this.cmsBlock.image.copyright.length > 0 : false
        },

        srcsets() {
            return this.getImageSrcsets(this.localize(this.cmsBlock.image).data.attributes)
        },

        hasWebp() {
            return this.srcsets['.webp'] ? true : false
        },

        originalFormat() {
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== '.webp') {
                    return format
                }
            }
            return null
        },

        teamMemberImageAltTag() {
            if (this.teamMember && this.teamMember.data.attributes.image.data) {
                return this.teamMember.data.attributes.image.data.attributes.alternativeText
            } else if (this.placeholderImage) {
                return this.placeholderImage.data.attributes.alternativeText
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .image-block {
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');
        line-height: 0;
        position: relative;
        overflow: hidden;
        max-width: $container-width;
        margin: 0 auto;

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: 0 0;
        }

        img {
            width: 100%;
            height: 800px;
            object-fit: cover;

            @include breakpoint('tablet') {
                height: 600px;
            }

            @include breakpoint('mobile') {
                height: 400px;
            }
        }

        .keep-aspect {
            img {
                height: auto;
            }
        }

        
    }

    .image-block.full_width {
        width: 100%;
        max-width: 100%;
        padding: 0 0;
    }
    .image-block.small_width {
        width: 33%;
        max-width: 33%;
        
        @include breakpoint('mobile') {
            width: 50%;
            max-width: 50%;
        }
    }
}


</style>
